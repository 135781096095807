/*!
  ______   ______    _    ____  _   _ _____ _____ ____  ____  _   _ _____ _   _    _    ____ ___ 
 | __ ) \ / / __ )  / \  / ___|| | | | ____| ____|  _ \/ ___|| | | | ____| | | |  / \  | __ )_ _|
 |  _ \\ V /|  _ \ / _ \ \___ \| |_| |  _| |  _| | |_) \___ \| |_| |  _| | |_| | / _ \ |  _ \| | 
 | |_) || | | |_) / ___ \ ___) |  _  | |___| |___|  _ < ___) |  _  | |___|  _  |/ ___ \| |_) | | 
 |____/ |_| |____/_/   \_\____/|_| |_|_____|_____|_| \_\____/|_| |_|_____|_| |_/_/   \_\____/___|
                                                                                                                                                                                                                                                                                                                                                                                                            
=========================================================
* BYBASHEERSHEHABI WEB - v1.0.0
=========================================================

* Product Page: https://www.bybasheershehabi.com/
* © 2023 ByBasheerShehabi LLC

* Designed and Coded ByBasheerShehabi LLC

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
