/*!
  ______   ______    _    ____  _   _ _____ _____ ____  ____  _   _ _____ _   _    _    ____ ___ 
 | __ ) \ / / __ )  / \  / ___|| | | | ____| ____|  _ \/ ___|| | | | ____| | | |  / \  | __ )_ _|
 |  _ \\ V /|  _ \ / _ \ \___ \| |_| |  _| |  _| | |_) \___ \| |_| |  _| | |_| | / _ \ |  _ \| | 
 | |_) || | | |_) / ___ \ ___) |  _  | |___| |___|  _ < ___) |  _  | |___|  _  |/ ___ \| |_) | | 
 |____/ |_| |____/_/   \_\____/|_| |_|_____|_____|_| \_\____/|_| |_|_____|_| |_/_/   \_\____/___|
                                                                                                                                                                                                                                                                                                                                                                                                            
=========================================================
* BYBASHEERSHEHABI WEB - v1.0.0
=========================================================

* Product Page: https://www.bybasheershehabi.com/
* © 2023 ByBasheerShehabi LLC

* Designed and Coded ByBasheerShehabi LLC

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "swiper/css/scrollbar";

.main-menu .navbar-collapse li ul {
  transition: 0.3s all;
  display: unset;
  left: 0;
  top: calc(100% + 20px);
  visibility: hidden;
  opacity: 0;
}
.main-menu .navbar-collapse .mobile-menu li ul {
  opacity: 1 !important;
  visibility: unset !important;
}
.main-menu .navbar-collapse li:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.main-menu .navbar-collapse li ul li ul {
  top: -2% !important;
}
.thumb-images .nav-link {
  padding: 0;
}

.testimonial-two-dots .slick-dots .swiper-pagination-bullet-active,
.testimonial-three-dots .slick-dots .swiper-pagination-bullet-active {
  background: #55e6a5;
}
.testimonial-three-dots .slick-dots .swiper-pagination-bullet {
  margin-left: 7px;
  margin-right: 7px;
}
.slick-dots .swiper-pagination-bullet {
  position: relative;
  cursor: pointer;
  width: 7px;
  height: 7px;
  transition: 0.5s;
  margin: 0 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.65);
}
.testimonial-two-part .slick-dots {
  display: flex !important;
  margin: 0 auto;
  text-align: center;
  margin-top: 100px;
}
.testimonial-two-part .slick-dots li.slick-active,
.testimonial-three-dots .slick-dots li.slick-active {
  background: var(--ygency-primary-color);
}
.work-gallery-active .project-item {
  margin: 0;
  margin-bottom: 60px;
}
.swiper-pagination-bullet {
  margin: 0 10px !important;
}
.service-about-image {
  position: relative;
}
.testimonial-item {
  padding: 30px 0 0;
}

.work-gallery-controls {
  width: 350px;
  justify-content: center;
  margin: 0 auto;
}
/* .project-item {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 15px !important;
} */
